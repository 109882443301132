import React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "./layout"
import SEO from "./seo"

const Play = ({ ctaSection }) => {
  return (
    <Layout>
      <SEO title="Play" />
      <div>
        <div
          className="h-[24px] rounded-t-none  sm:rounded-t-[15px]
                        rounded-t-[20px]
                        accent-gradient-bg
                        w-full
                        "
        />
        {/* Header */}
        <div className="w-full flex flex-col items-center justify-center rounded-b-[15px] pt-[32px] lg:pt-[48px] px-[16px] lg:px-0 gap-[36px] lg:gap-[48px] mb-[64px] hero-bg pill-shadow relative overflow-hidden">
          <div
            style={{ maxWidth: `440px` }}
            className="lg:absolute lg:block top-[-50px] right-[-50px] hidden"
          >
            <StaticImage
              src="../images/vector.png"
              alt="vector icon"
              className=""
            />
          </div>
          <div style={{ maxWidth: `250px`, marginBottom: `1.45rem` }}>
            <StaticImage
              src="../images/bb_logo.png"
              alt="Big Brain Energy logo"
            />
          </div>
          <div className="flex gap-[96px] justify-center flex-col lg:flex-row">
            <div className="flex flex-col items-start gap-[36px] w-full max-w-[568px] relative">
              <div
                style={{ maxWidth: `380px` }}
                className="lg:absolute lg:block hidden bottom-[200px] z-0"
              >
                <StaticImage
                  src="../images/ellipse.png"
                  alt="bg ellipse"
                  className=""
                />
              </div>
              <p
                class="w-fit flex justify-center items-center gap-[9px] p-[16px_20px] rounded-[8px] z-[1]
                            text-[#892FE0] font-semibold
                            bg-white shadow"
              >
                ⚔️&nbsp;&nbsp;Face off your friends and learn something new
              </p>
              <h1 class="text-[#141414] text-[42px] font-semibold leading-[54.6px] tracking-[-0.84px] z-[1]">
                Play trivia duels with unique quizzes{" "}
                <span class="bg-[conic-gradient(from_199deg_at_46.09%_53.52%,_#842ED8_111.46745681762695deg,_#DB28A9_212.01815128326416deg,_#9D1DCA_360deg)] bg-clip-text text-transparent font-[General_Sans] text-[42px] italic font-semibold leading-[130%] tracking-[-0.84px] pr-[15px]">
                  about anything!
                </span>
              </h1>
              <p class="text-[#141414] text-[24px] font-medium leading-[150%] z-[1]">
                Big Brain Energy is a trivia game where AI creates niche quizzes
                on any topic you want!
              </p>
              {ctaSection}
            </div>
            <div>
              <div style={{ maxWidth: `410px` }}>
                <StaticImage src="../images/bb1-new.png" alt="mockup" />
              </div>
            </div>
          </div>
        </div>

        {/* how it works */}
        <div className="w-full flex flex-col items-center justify-center rounded-[15px] px-[16px] lg:px-0 py-[48px] mb-[64px] gap-[48px] bg-[rgba(226,234,245,0.2)] pill-shadow">
          <div className="flex flex-col items-center justify-center gap-[16px] max-w-[768px]">
            <div
              className="w-fit text-[16px] font-bold p-[12px] rounded-[4px] text-[#892FE0]
                                        pill-bg shadow"
            >
              How it works
            </div>
            <h2 className="text-[#101828] text-[36px] font-semibold leading-[130%] tracking-[-0.72px] text-center">
              Test your knowledge with unique quizzes about&nbsp;
              <span class="bg-[conic-gradient(from_199deg_at_46.09%_53.52%,_#842ED8_111.46745681762695deg,_#DB28A9_212.01815128326416deg,_#9D1DCA_360deg)] bg-clip-text text-transparent font-[General_Sans] text-[42px] italic font-semibold leading-[130%] tracking-[-0.84px] pr-[5px]">
                infinite topics
              </span>
              &nbsp;⚡️🥊
            </h2>
          </div>
          <div className="flex flex-col-reverse lg:flex-row items-center gap-[48px] lg:gap-[96px]">
            <div
              class="relative lg:static lg:left-0"
              style={{ maxWidth: `520px`, left: "-20px" }}
            >
              <StaticImage src="../images/bb2.png" alt="mockup" />
            </div>
            <div>
              <div className="flex items-center gap-[24px] pb-[16px] max-w-[380px]">
                <span class="inline-flex items-center justify-center w-10 h-10 bg-[#F2F4F7] text-[#000] text-[20px] font-medium rounded-full">
                  1
                </span>
                <p className="text-[20px] text-[#141414]">
                  Tell us what you want the quizz to be about
                </p>
              </div>
              <div className="flex items-center gap-[24px] pb-[16px] max-w-[380px]">
                <span class="flex-none inline-flex items-center justify-center w-10 h-10 bg-[#F2F4F7] text-[#000] text-[20px] font-medium rounded-full">
                  2
                </span>
                <p className="text-[20px] text-[#141414]">
                  Our AI will generate a fun and unique trivia game around the
                  topic
                </p>
              </div>
              <div className="flex items-center gap-[24px] pb-[16px] max-w-[380px]">
                <span class="flex-none inline-flex items-center justify-center w-10 h-10 bg-[#F2F4F7] text-[#000] text-[20px] font-medium rounded-full">
                  3
                </span>
                <p className="text-[20px] text-[#141414]">
                  Play in solo mode, invite friends to a private game, or face
                  off anyone online
                </p>
              </div>
              <div className="flex items-center gap-[24px] pb-[16px] max-w-[380px]">
                <div style={{ maxWidth: `45px` }}>
                  <StaticImage src="../images/rules-icon.png" alt="mockup" />
                </div>
                <p className="text-[20px] text-[#141414]">
                  <span className="font-semibold">Game rules: </span>10
                  questions, 4 choices, 10 seconds to answer ⚡️
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* users like */}
        <div className="relative w-full flex flex-col items-center justify-center rounded-[15px] pt-[48px] pb-[0px] lg:py-[48px] px-[16px] lg:px-0 gap-[48px] pill-shadow bg-[rgba(253,229,234,0.2)] overflow-hidden">
          <div
            style={{ maxWidth: `450px` }}
            className="lg:absolute lg:block top-[-20px] right-[-50px] hidden z-0"
          >
            <StaticImage
              src="../images/vector_2.png"
              alt="vector icon"
              className=""
            />
          </div>
          <div className="flex flex-col items-center justify-center gap-[16px]">
            <div
              className="w-fit text-[16px] font-bold p-[12px] rounded-[4px] text-[#892FE0]
                                        pill-bg shadow"
            >
              Test your knowledge about anything
            </div>
            <h2 className="text-[#101828] text-[36px] font-semibold leading-[130%] tracking-[-0.72px] z-[1]">
              Our users are duelling about...
            </h2>
          </div>
          <div className="flex flex-col-reverse lg:flex-row items-center gap-[48px] lg:gap-[96px] z-[1]">
            <div
              class="relative lg:static lg:left-0"
              style={{ maxWidth: `500px`, left: "-10px" }}
            >
              <StaticImage src="../images/bb3.png" alt="mockup" />
            </div>
            <div>
              <p className="text-[20px] text-[#141414] pb-[16px]">
                "Manchester United legends"
              </p>
              <p className="text-[20px] text-[#141414] pb-[16px]">
                "Obscure Roman Emperors"
              </p>
              <p className="text-[20px] text-[#141414] pb-[16px]">
                "90s action movies"
              </p>
              <p className="text-[20px] text-[#141414] pb-[16px]">
                "Minor Harry Potter characters"
              </p>
              <p className="text-[20px] text-[#141414] pb-[32px]">
                "Beatles iconic lyrics"
              </p>
              <p className="text-[20px] text-[#141414] font-semibold">
                But you don't have to, you should do you 😉
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

Play.propTypes = {
  ctaSection: PropTypes.node.isRequired,
}

export default Play
