import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Play from "../components/play"

const hardCtaSection = () => (
  <div className="flex justify-center lg:justify-start w-full lg:w-fit lg:px-[30px] gap-[35px] lg:gap-[50px]">
    <div className="flex flex-col items-center gap-[18px]">
      <p className="text-[#475467] text-[16px] italic">Download the app</p>
      <div className="flex flex-col lg:flex-row items-center gap-[10px]">
        <div style={{ maxWidth: `200px` }}>
          <a
            href="https://apps.apple.com/us/app/big-brain-energy-ai-trivia/id6504838385?platform=iphone"
            target="_blank"
          >
            <StaticImage src="../images/app_store.png" alt="App Store" />
          </a>
        </div>
      </div>
    </div>
  </div>
)

const PlayPage = () => {
  return <Play ctaSection={hardCtaSection()} />
}

export default PlayPage
